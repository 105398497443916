// @for $row from 0 through 3 {
//   @for $column from 0 through 3 {
//     .position_#{$row}_#{$column}:not(.isMoving) {
//       top: 90 * $row + 150px;
//       left: 90 * $column + 150px;
//     }
//   }
// }

// @for $fromRow from 0 through 3 {
//   @for $toRow from 0 through 3 {
//     $name: row_from_#{$fromRow}_to_#{$toRow};

//     @if $fromRow == $toRow {
//       .#{$name} {
//         top: 90 * $toRow + 150px;
//       }
//     } @else {
//       .#{$name} {
//         animation-duration: 0.2s;
//         animation-name: $name;
//         animation-fill-mode: forwards;
//       }

//       @keyframes #{$name} {
//         from {
//           top: 90 * $fromRow + 150px;
//         }
//         to {
//           top: 90 * $toRow + 150px;
//         }
//       }
//     }
//   }
// }

// @for $fromColumn from 0 through 3 {
//   @for $toColumn from 0 through 3 {
//     $name: column_from_#{$fromColumn}_to_#{$toColumn};

//     @if $fromColumn == $toColumn {
//       .#{$name} {
//         left: 90 * $toColumn + 150px;
//       }
//     } @else {
//       .#{$name} {
//         animation-duration: 0.2s;
//         animation-name: $name;
//         animation-fill-mode: forwards;
//       }

//       @keyframes #{$name} {
//         from {
//           left: 90 * $fromColumn + 150px;
//         }
//         to {
//           left: 90 * $toColumn + 150px;
//         }
//       }
//     }
//   }
// }

// @media (max-width: 1024px) {
@for $row from -1 through 3 {
  @for $column from -1 through 3 {
    .position_#{$row}_#{$column}:not(.isMoving) {
      top: 80 * $row + 100px;
      left: 80 * $column + 100px;
    }
    $name: position_#{$row}_#{$column};
    @if $row == -1 {
      .#{$name} {
        display: none;
      }
    }
    @if $column == -1 {
      .#{$name} {
        display: none;
      }
    }
  }
}

@for $fromRow from -1 through 3 {
  @for $toRow from -1 through 3 {
    $name: row_from_#{$fromRow}_to_#{$toRow};

    @if $fromRow == $toRow {
      .#{$name} {
        top: 80 * $toRow + 100px;
      }
    } @else {
      .#{$name} {
        animation-duration: 0.2s;
        animation-name: $name;
        animation-fill-mode: forwards;
      }

      @keyframes #{$name} {
        from {
          top: 80 * $fromRow + 100px;
        }
        to {
          top: 80 * $toRow + 100px;
        }
      }
    }
    //
    @if $fromRow == -1 {
      .#{$name} {
        animation-duration: none !important;
        animation-name: none;
        animation-fill-mode: none;
        display: none !important;
      }
    }
    @if $toRow == -1 {
      .#{$name} {
        animation-duration: none !important;
        animation-name: none;
        animation-fill-mode: none;
        display: none !important;
      }
    }
  }
}

@for $fromColumn from -1 through 3 {
  @for $toColumn from -1 through 3 {
    $name: column_from_#{$fromColumn}_to_#{$toColumn};

    @if $fromColumn == $toColumn {
      .#{$name} {
        left: 80 * $toColumn + 100px;
      }
    } @else {
      .#{$name} {
        animation-duration: 0.2s;
        animation-name: $name;
        animation-fill-mode: forwards;
      }

      @keyframes #{$name} {
        from {
          left: 80 * $fromColumn + 100px;
        }
        to {
          left: 80 * $toColumn + 100px;
        }
      }
    }
    //
    @if $fromColumn == -1 {
      .#{$name} {
        animation-duration: none;
        animation-name: none;
        animation-fill-mode: none;
        display: none;
      }
    }
    @if $toColumn == -1 {
      .#{$name} {
        animation-duration: none;
        animation-name: none;
        animation-fill-mode: none;
        display: none;
      }
    }
  }
}
// }

@media (max-width: 768px) {
  @for $row from 0 through 3 {
    @for $column from 0 through 3 {
      .position_#{$row}_#{$column}:not(.isMoving) {
        top: 60 * $row + 80px;
        left: 60 * $column + 80px;
      }
    }
  }

  @for $fromRow from 0 through 3 {
    @for $toRow from 0 through 3 {
      $name: row_from_#{$fromRow}_to_#{$toRow};

      @if $fromRow == $toRow {
        .#{$name} {
          top: 60 * $toRow + 80px;
        }
      } @else {
        .#{$name} {
          animation-duration: 0.2s;
          animation-name: $name;
          animation-fill-mode: forwards;
        }

        @keyframes #{$name} {
          from {
            top: 60 * $fromRow + 80px;
          }
          to {
            top: 60 * $toRow + 80px;
          }
        }
      }
    }
  }

  @for $fromColumn from 0 through 3 {
    @for $toColumn from 0 through 3 {
      $name: column_from_#{$fromColumn}_to_#{$toColumn};

      @if $fromColumn == $toColumn {
        .#{$name} {
          left: 60 * $toColumn + 80px;
        }
      } @else {
        .#{$name} {
          animation-duration: 0.2s;
          animation-name: $name;
          animation-fill-mode: forwards;
        }

        @keyframes #{$name} {
          from {
            left: 60 * $fromColumn + 80px;
          }
          to {
            left: 60 * $toColumn + 80px;
          }
        }
      }
    }
  }
}

@media (max-width: 425px) {
  @for $row from 0 through 3 {
    @for $column from 0 through 3 {
      .position_#{$row}_#{$column}:not(.isMoving) {
        top: 50 * $row + 60px;
        left: 50 * $column + 60px;
      }
    }
  }

  @for $fromRow from 0 through 3 {
    @for $toRow from 0 through 3 {
      $name: row_from_#{$fromRow}_to_#{$toRow};

      @if $fromRow == $toRow {
        .#{$name} {
          top: 50 * $toRow + 60px;
        }
      } @else {
        .#{$name} {
          animation-duration: 0.2s;
          animation-name: $name;
          animation-fill-mode: forwards;
        }

        @keyframes #{$name} {
          from {
            top: 50 * $fromRow + 60px;
          }
          to {
            top: 50 * $toRow + 60px;
          }
        }
      }
    }
  }

  @for $fromColumn from 0 through 3 {
    @for $toColumn from 0 through 3 {
      $name: column_from_#{$fromColumn}_to_#{$toColumn};

      @if $fromColumn == $toColumn {
        .#{$name} {
          left: 50 * $toColumn + 60px;
        }
      } @else {
        .#{$name} {
          animation-duration: 0.2s;
          animation-name: $name;
          animation-fill-mode: forwards;
        }

        @keyframes #{$name} {
          from {
            left: 50 * $fromColumn + 60px;
          }
          to {
            left: 50 * $toColumn + 60px;
          }
        }
      }
    }
  }
}

@media (max-width: 320px) {
  @for $row from 0 through 3 {
    @for $column from 0 through 3 {
      .position_#{$row}_#{$column}:not(.isMoving) {
        top: 40 * $row + 50px;
        left: 40 * $column + 50px;
      }
    }
  }

  @for $fromRow from 0 through 3 {
    @for $toRow from 0 through 3 {
      $name: row_from_#{$fromRow}_to_#{$toRow};

      @if $fromRow == $toRow {
        .#{$name} {
          top: 40 * $toRow + 50px;
        }
      } @else {
        .#{$name} {
          animation-duration: 0.2s;
          animation-name: $name;
          animation-fill-mode: forwards;
        }

        @keyframes #{$name} {
          from {
            top: 40 * $fromRow + 50px;
          }
          to {
            top: 40 * $toRow + 50px;
          }
        }
      }
    }
  }

  @for $fromColumn from 0 through 3 {
    @for $toColumn from 0 through 3 {
      $name: column_from_#{$fromColumn}_to_#{$toColumn};

      @if $fromColumn == $toColumn {
        .#{$name} {
          left: 40 * $toColumn + 50px;
        }
      } @else {
        .#{$name} {
          animation-duration: 0.2s;
          animation-name: $name;
          animation-fill-mode: forwards;
        }

        @keyframes #{$name} {
          from {
            left: 40 * $fromColumn + 50px;
          }
          to {
            left: 40 * $toColumn + 50px;
          }
        }
      }
    }
  }
}
