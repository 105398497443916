$purple-color: #3d087b;
$pink-color: #f43b86;
$pink2-color: hsl(336, 96%, 45%);
$black-color: #11052c;
$yello-color: #ffe459;

$tablet-width: 768px;
$desktop-width: 1024px;

@font-face {
  font-family: 'Clear Sans';
  src: url('./clear-sans.ttf') format('truetype');
}

.game_container {
  min-height: 100vh;
  padding-top: 5px;
  padding-bottom: 30px;
  position: relative;
  .details_container {
    width: 200px;
    position: absolute;
    right: 2rem;
    top: 2rem;
    h2 {
      font-size: 1.6rem;
    }
  }
}

#room-text {
  height: 20px;
}

.container-game {
  width: 100%;
  margin-top: 30px;
  // min-height: 100vh;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  align-items: center;
  place-items: center;
  @media (max-width: $desktop-width) {
    grid-template-columns: 1fr;
  }
}
#headers_h1 {
  font-size: 2rem;
  margin: 5px 0;
}
.flex-btn {
  display: flex;
  justify-content: center;
}
.find-game {
  width: 120px;
  border-radius: 10px;
  background-color: $pink-color;
  color: white;
  padding: 10px 0;
  text-align: center;
  cursor: pointer;
  border: 0;
  font-weight: 600;
  margin: 0 10px;
  font-size: 16px;
}
.find-game:disabled {
  background-color: #bbbbbb;
  color: black;
  cursor: not-allowed;
}

.hide-em {
  background-color: $yello-color;
  color: $pink-color;
}

.find-game:hover {
  background-color: $yello-color;
}

.find-game:disabled:hover {
  background-color: #bbbbbb;
  color: black;
}

.board-container {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  width: auto;
  height: auto;
  h2 {
    margin: 0;
    font-size: 30px;
  }
}

h1 {
  text-align: center;
  color: white;
}

.text {
  order: 2;
  padding-top: 40px;
  width: 440px;
  font-weight: bold;
}

.board {
  order: 1;
  width: auto;
  height: auto;
  padding: 5px;
  background-color: #57407c;
  border-radius: 7px;
  outline: none;
  position: relative;
}

.score_contain {
  display: flex;
  justify-content: flex-end;
  div {
    background-color: $pink-color;
    font-size: 30px;
    font-weight: 600;
    color: white;
    padding: 10px 10px;
    width: 100%;
    margin-bottom: 5px;
    border-radius: 10px;
  }
}

.board .cell,
.tile {
  user-select: none;
  cursor: default;
}

.details-box {
  display: flex;
  align-items: center;
  padding: 10px;
  justify-content: space-between;
}

.resetButton {
  background-color: #3d2963;
  color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
  font-size: 25px;
  padding: 10px 20px;
  border-radius: 7px;
  transition: color 0.3s ease, background-color 0.3s ease;
  &:hover {
    background-color: #d3386a;
    color: rgba(255, 255, 255, 1);
  }
}

.timeDetails {
  margin-top: 10px;
  background-color: #ffffff;
  color: #3d2963;
  cursor: pointer;
  font-size: 25px;
  padding: 10px 20px;
  border-radius: 7px;
  transition: color 0.3s ease, background-color 0.3s ease;
  &:hover {
    background-color: #d3386a;
    color: rgba(255, 255, 255, 1);
  }
}

.score-header {
  font-size: 50%;
}

.score-box {
  background-color: #3d2963;
  color: rgba(255, 255, 255, 0.5);
  min-width: 50px;
  width: 70px;
  padding: 5px 15px;
  border-radius: 7px;
}

.cell,
.tile {
  margin: 5px;
  line-height: 90px;
  display: inline-block;
  font-size: 55px;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  border-radius: 1rem;
  font-family: 'Clear Sans';
  color: white;
  background-size: contain;
}

.tile0 {
  background-color: #dcb;
}

.tile2 {
  background-color: #eee;
}

.tile4 {
  background-color: #eec;
}

.tile8 {
  color: #ffe;
}

.tile16 {
  color: #ffe;
}

.tile32 {
  color: #ffe;
}

.tile64 {
  color: #ffe;
}

.tile128 {
  color: #ffe;
}

.tile256 {
  color: #ffe;
  font-size: 45px;
}

.tile512 {
  color: #ffe;
  font-size: 45px;
}

.tile1024 {
  color: #fff;
  font-size: 35px;
}

.tile2048 {
  position: absolute;
  background-size: contain;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 0px;
  color: #fff;
  font-size: 35px;
}

.try-again {
  z-index: 10;
  height: 100px;
  width: 100px;
}

.tile {
  position: absolute;
}

.tile.merged {
  display: none;
}

.tile.merged.isMoving {
  display: inline;
}

.tile.new,
.overlay {
  // background-image: url(../../public/image/nft/number/2048.gif);
  animation-duration: 0.2s;
  animation-name: newTile;
  animation-fill-mode: forwards;
  animation-delay: 0.15s;
  transform: scale(0);
}

@keyframes newTile {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.overlay {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 55px;
  font-weight: bolder;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-radius: 10px;
}

.tryAgain {
  background-color: #876;
  color: #fff;
  height: 40px;
  width: 200px;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  border: 2px solid white;
}

.gameOver {
  background-size: contain;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  text-align: center;
}

.overlay .message {
  color: red;
  background-color: yellow;
  font-size: 20px;
}

@media (max-width: 500px) {
  .try-again {
    width: 60px;
    height: 60px;
    font-size: 35px;
  }
  #headers_h1 {
    padding-left: 10px;
    text-align: left;
  }
  .flex-btn {
    justify-content: flex-start;
  }
  .find-game {
    margin: 0;
    margin-left: 10px;
  }
  .score_contain {
    div {
      font-size: 16px;
    }
  }
  .game_container {
    .details_container {
      width: 100px;
      position: absolute;
      right: 0rem;
      top: 0rem;
      h2 {
        font-size: 1rem;
      }
    }
  }
}

.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.fade-leave {
  opacity: 1;
}

.fade-leave.fade-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}
